<template>
  <v-dialog
    v-model="show"
    @click:outside="fechar"
    @keydown.esc="fechar"
    width="900px"
    scrollable
  >
    <v-card outlined>
      <v-card-title class="tocs_gray_1 pa-4">
        <span class="px-2">
          {{ $tc("global.pagamento") }}
        </span>
        <v-spacer></v-spacer>
        <v-btn @click="fechar" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-4 text-subtitle-1">
        <div class="d-flex justify-center align-center mt-2">
          <div class="ml-6">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="primary" @click="resetaBloqueios"
                  >mdi-autorenew</v-icon
                >
              </template>
              <span>Reset</span>
            </v-tooltip>
          </div>
          <v-spacer></v-spacer>
          <div>{{ this.$tc("global.total") }}:</div>
          <v-card class="ml-3 pa-2">
            <Currency :moeda_sigla="moeda" :valor="valor" />
          </v-card>
          <v-spacer></v-spacer>
        </div>
        <v-row>
          <v-col class="px-10 mt-4">
            <ComboboxFormaPagamento
              :forma_pagamento.sync="selectedFormaPagamento"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="mx-7">
            <v-card outlined flat>
              <BaixaLoteFormaPagamentoCheque
                v-if="selectedFormaPagamento && selectedFormaPagamento.id === 4"
                :valor_restante="valor"
                :moeda_venda="moeda"
                :allowCheque.sync="allowCheque"
                :cheque.sync="chequeAdd"
              />
            </v-card>
          </v-col>
        </v-row>

        <v-row v-if="tipo_caixa == 'MOV'">
          <v-col class="px-10">
            <SelectTimbrado :timbrado_sync.sync="selectedTimbrado" />
          </v-col>
        </v-row>
        <div
          class="mt-2 px-8"
          v-if="selectedFormaPagamento && selectedFormaPagamento.id === 1"
        >
          <v-row>
            <v-col>
              <span>Valor recebido:</span>
            </v-col>
            <v-col class="text-right">
              <v-text-field
                v-model="valor_recebido_guarani"
                outlined
                dense
                v-currency="currency_options_guarani"
                hide-details
                :disabled="disableValorRecebidoGuarani"
                @blur="disableValorRecebidoGuarani = true"
              >
                <template v-slot:prepend-inner
                  ><span class="pt-1">G$</span></template
                >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="valor_recebido_real"
                outlined
                dense
                v-currency="currency_options_real"
                hide-details
                :disabled="disableValorRecebidoReal"
                @blur="disableValorRecebidoReal = true"
              >
                <template v-slot:prepend-inner
                  ><span class="pt-1">R$</span></template
                >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="valor_recebido_dolar"
                outlined
                dense
                v-currency="currency_options_dolar"
                hide-details
                :disabled="disableValorRecebidoDolar"
                @blur="disableValorRecebidoDolar = true"
              >
                <template v-slot:prepend-inner
                  ><span class="pt-1">U$</span></template
                >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span>Valor a receber:</span>
            </v-col>
            <v-col class="text-right">
              <v-text-field
                :value="valor_receber_guarani"
                outlined
                disabled
                dense
                v-currency="currency_options_guarani"
                hide-details
              >
                <template v-slot:prepend-inner
                  ><span class="pt-1">G$</span></template
                >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                :value="valor_receber_real"
                outlined
                disabled
                dense
                v-currency="currency_options_real"
                hide-details
              >
                <template v-slot:prepend-inner
                  ><span class="pt-1">R$</span></template
                >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="valor_receber_dolar"
                outlined
                disabled
                dense
                v-currency="currency_options_dolar"
                hide-details
              >
                <template v-slot:prepend-inner
                  ><span class="pt-1">U$</span></template
                >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span>Valor devolvido:</span>
            </v-col>
            <v-col class="text-right">
              <v-text-field
                :value="valor_devolvido_guarani"
                outlined
                dense
                v-currency="currency_options_guarani"
                @blur="disableValorDevolvidoGuarani = true"
                :disabled="disableValorDevolvidoGuarani"
                hide-details
              >
                <template v-slot:prepend-inner
                  ><span class="pt-1">G$</span></template
                >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="valor_devolvido_real"
                outlined
                dense
                v-currency="currency_options_real"
                hide-details
                @blur="disableValorDevolvidoReal = true"
                :disabled="disableValorDevolvidoReal"
              >
                <template v-slot:prepend-inner
                  ><span class="pt-1">R$</span></template
                >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="valor_devolvido_dolar"
                outlined
                dense
                v-currency="currency_options_dolar"
                hide-details
                @blur="disableValorDevolvidoDolar = true"
                :disabled="disableValorDevolvidoDolar"
              >
                <template v-slot:prepend-inner
                  ><span class="pt-1">U$</span></template
                >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span>Valor a devolver:</span>
            </v-col>
            <v-col class="text-right">
              <v-text-field
                :value="valor_devolver_guarani"
                outlined
                disabled
                dense
                v-currency="currency_options_guarani"
                hide-details
              >
                <template v-slot:prepend-inner
                  ><span class="pt-1">G$</span></template
                >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="valor_devolver_real"
                outlined
                disabled
                dense
                v-currency="currency_options_real"
                hide-details
              >
                <template v-slot:prepend-inner
                  ><span class="pt-1">R$</span></template
                >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="valor_devolver_dolar"
                outlined
                disabled
                dense
                v-currency="currency_options_dolar"
                hide-details
              >
                <template v-slot:prepend-inner
                  ><span class="pt-1">U$</span></template
                >
              </v-text-field>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="mb-2 py-5 pb-4 px-6">
        <span> Câmbio {{ hoje }} </span>
        <v-card class="ml-3 pa-2">
          R$ 1 = <Currency :moeda_sigla="'G$'" :valor="guaranis_para_1_real" />
        </v-card>
        <v-card class="ml-3 pa-2">
          U$ 1 = <Currency :moeda_sigla="'G$'" :valor="guaranis_para_1_dolar" />
        </v-card>
        <v-spacer></v-spacer>
        <v-btn
          color="button_1"
          :disabled="disableFaturar"
          class="white--text"
          @click="faturar"
        >
          <v-icon left>mdi-cash-fast</v-icon>
          {{ $tc("global.faturar") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { cambioDia } from "@/api/cambio/cambios.js";
import { cambioResolve } from "@/helpers/utils";

export default {
  name: "DialogFaturar",

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    valor: {
      type: Number,
      default: 0,
    },
    moeda: {
      type: String,
      default: "G$",
    },
    tipo_caixa: {
      type: String,
      default: "PDV",
    },
  },

  data() {
    return {
      contas: [],
      loading: true,
      search: "",
      selectedFornecedor: null,
      dialogConta: false,
      fornecedor_nome: null,
      fornecedor_id: null,
      formas_pagamento: [],
      valor_recebido: null,
      cambios: null,
      cambio_real: null,
      cambio_guarani: null,
      cambio_dolar: null,
      guaranis_para_1_real: null,
      guaranis_para_1_dolar: null,
      hoje: new Date().toLocaleDateString(),
      valor_recebido_guarani: null,
      valor_recebido_real: null,
      valor_recebido_dolar: null,
      valor_devolvido_guarani: null,
      valor_devolvido_real: null,
      valor_devolvido_dolar: null,
      disableValorRecebidoGuarani: false,
      disableValorRecebidoReal: false,
      disableValorRecebidoDolar: false,
      disableValorDevolvidoGuarani: false,
      disableValorDevolvidoReal: false,
      disableValorDevolvidoDolar: false,
      selectedTimbrado: null,
      selectedFormaPagamento: null,
      allowCheque: false,
      chequeAdd: null,
    };
  },

  components: {
    Currency: () => import("@/components/global/Currency.vue"),
    ComboboxFormaPagamento: () =>
      import("@/components/fields/ComboboxFormaPagamento.vue"),
    SelectTimbrado: () => import("@/components/fields/SelectTimbrado.vue"),
    BaixaLoteFormaPagamentoCheque: () =>
      import("./BaixaLoteFormaPagamentoCheque.vue"),
  },

  computed: {
    headers() {
      return [
        {
          text: this.$tc("global.formapagamento"),
          value: "forma_pagamento",
        },
        {
          text: "Destino",
          value: "destino",
        },
        {
          text: this.$tc("global.valor"),
          value: "valor",
        },
        {
          text: "Consolidado",
          value: "consolidado",
        },
        {
          text: "Excluir",
          value: "actions",
        },
      ];
    },
    currency_options_guarani() {
      return {
        precision: 0,
        distractionFree: false,
      };
    },
    currency_options_real() {
      return {
        precision: 2,
        distractionFree: false,
      };
    },
    currency_options_dolar() {
      return {
        precision: 2,
        distractionFree: false,
      };
    },
    valor_receber_guarani() {
      return cambioResolve(
        "G$",
        this.total_consolidado - this.total_recebido_consolidado,
        "U$",
        1
      );
    },
    valor_receber_real() {
      return cambioResolve(
        "G$",
        this.total_consolidado - this.total_recebido_consolidado,
        "R$",
        this.guaranis_para_1_real
      ).toFixed(2);
    },
    valor_receber_dolar() {
      return cambioResolve(
        "G$",
        this.total_consolidado - this.total_recebido_consolidado,
        "U$",
        this.guaranis_para_1_dolar
      ).toFixed(2);
    },
    valor_devolver_dolar() {
      return cambioResolve(
        "G$",
        this.total_recebido_consolidado -
          this.total_consolidado -
          this.valor_devolvido_consolidado,
        "U$",
        this.guaranis_para_1_dolar
      ).toFixed(2);
    },
    valor_devolver_real() {
      return cambioResolve(
        "G$",
        this.total_recebido_consolidado -
          this.total_consolidado -
          this.valor_devolvido_consolidado,
        "R$",
        this.guaranis_para_1_real
      ).toFixed(2);
    },
    valor_devolver_guarani() {
      return cambioResolve(
        "G$",
        this.total_recebido_consolidado -
          this.total_consolidado -
          this.valor_devolvido_consolidado,
        "U$",
        1
      );
    },
    valor_devolvido_consolidado() {
      let total = 0;
      let real_em_guarani = cambioResolve(
        "R$",
        this.$ci.parse(this.valor_devolvido_real, this.currency_options_real),
        "G$",
        this.guaranis_para_1_real
      );
      let dolar_em_guarani = cambioResolve(
        "U$",
        this.$ci.parse(this.valor_devolvido_dolar, this.currency_options_dolar),
        "G$",
        this.guaranis_para_1_dolar
      );
      total =
        real_em_guarani +
        dolar_em_guarani +
        this.$ci.parse(
          this.valor_devolvido_guarani,
          this.currency_options_guarani
        );
      return Number(total);
    },
    total_recebido_consolidado() {
      let total = 0;
      let real_em_guarani = cambioResolve(
        "R$",
        this.$ci.parse(this.valor_recebido_real, this.currency_options_real),
        "G$",
        this.guaranis_para_1_real
      );
      let dolar_em_guarani = cambioResolve(
        "U$",
        this.$ci.parse(this.valor_recebido_dolar, this.currency_options_dolar),
        "G$",
        this.guaranis_para_1_dolar
      );
      total =
        real_em_guarani +
        dolar_em_guarani +
        this.$ci.parse(
          this.valor_recebido_guarani,
          this.currency_options_guarani
        );
      return Number(total);
    },
    total_devolvido_consolidado() {
      let total = 0;
      let real_em_guarani = cambioResolve(
        "R$",
        this.$ci.parse(this.valor_devolvido_real, this.currency_options_real),
        "G$",
        this.guaranis_para_1_real
      );
      let dolar_em_guarani = cambioResolve(
        "U$",
        this.$ci.parse(this.valor_devolvido_dolar, this.currency_options_dolar),
        "G$",
        this.guaranis_para_1_dolar
      );
      total =
        real_em_guarani +
        dolar_em_guarani +
        this.$ci.parse(
          this.valor_devolvido_guarani,
          this.currency_options_guarani
        );
      return Number(total);
    },
    disableFaturar() {
      if (this.tipo_caixa == "MOV" && !this.selectedTimbrado) {
        return true;
      }
      if (
        (Math.abs(this.total_consolidado) - this.total_recebido_consolidado <=
          100 &&
          Math.abs(this.total_consolidado) - this.total_recebido_consolidado >=
            -100) ||
        (Math.abs(this.total_consolidado) - this.total_devolvido_consolidado <=
          100 &&
          Math.abs(this.total_consolidado) - this.total_devolvido_consolidado >=
            -100)
      ) {
        return false;
      }
      if (
        this.selectedFormaPagamento &&
        this.selectedFormaPagamento.id == 4 &&
        this.allowCheque
      ) {
        return false;
      }
      return true;
    },
    total_consolidado() {
      let valor = this.valor;
      if (this.moeda == "R$") {
        valor = cambioResolve(
          "R$",
          this.valor,
          "G$",
          this.guaranis_para_1_real
        );
      }
      if (this.moeda == "U$") {
        valor = cambioResolve(
          "U$",
          this.valor,
          "G$",
          this.guaranis_para_1_dolar
        );
      }
      return valor;
    },
  },

  watch: {},

  methods: {
    fechar() {
      this.$emit("update:show", false);
    },
    faturar() {
      if (this.selectedTimbrado) {
        this.$emit("timbrado", this.selectedTimbrado);
      }

      let forma_pagamento = {};
      let formaPagamentoId = this.selectedFormaPagamento.id;

      switch (formaPagamentoId) {
        case 1:
          forma_pagamento.forma_pagamento_id = formaPagamentoId;
          forma_pagamento.moeda_sigla = this.moeda;
          break;
        case 4:
          forma_pagamento.forma_pagamento_id = formaPagamentoId;
          forma_pagamento.cheque = this.chequeAdd;
          break;
      }

      this.$emit("faturar", forma_pagamento);
    },

    resetaBloqueios() {
      this.disableValorRecebidoGuarani = false;
      this.disableValorRecebidoReal = false;
      this.disableValorRecebidoDolar = false;
      this.valor_recebido_guarani = null;
      this.valor_recebido_real = null;
      this.valor_recebido_dolar = null;
      this.disableValorDevolvidoGuarani = false;
      this.disableValorDevolvidoReal = false;
      this.disableValorDevolvidoDolar = false;
      this.valor_devolvido_guarani = null;
      this.valor_devolvido_real = null;
      this.valor_devolvido_dolar = null;
      this.selectedFormaPagamento = null;
      this.chequeAdd = null;
    },
  },

  async mounted() {
    this.cambios = await cambioDia();
    this.guaranis_para_1_real = this.cambios.guarani_real;
    this.guaranis_para_1_dolar = this.cambios.guarani;
  },
};
</script>

<style></style>
